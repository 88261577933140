import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { ParagraphWrapper } from '../ParagraphWrapper';
import { SmartLink } from '../../SmartLink';
import { t } from '../../../domain/services/configService';
import { getTravelGuideInfo } from '../../../domain/utils/contentUtils/hubPage/travelGuideUtils';
import { theme } from '../../../domain/theme';

const TravelGuideBreadcrumbsParagraphInner = ({ content, colors }) => {
  const styles = {
    main: css`
      ${theme.font.family('regularWeb')};
      font-size: ${theme.font.size('s')};
      padding: ${theme.spacing.parse('0 $base')};
      margin: ${theme.spacing.parse('$base auto')};
      width: 100%;
      max-width: 84rem;
      list-style-type: none;
      background: ${colors.background};
      color: ${colors.color};
      li {
        display: inline-block;
        margin: 0 ${theme.spacing('xs3')};
        &:last-child {
          ${theme.font.family('boldWeb')};
        }
        display: none;
        &:nth-last-of-type(2) {
          display: inline-block;
        }
        ${theme.mq.tablet} {
          display: inline-block;
        }
      }
      a {
        color: ${colors.color};
        :hover {
          color: ${colors.color === '#fff' ? '#e6e6e6' : '#333'};
          text-decoration: underline;
        }
        &::before {
          content: '<';
          margin-right: ${theme.spacing('xs2')};
        }
        ${theme.mq.tablet} {
          display: inline-block;
          &::before {
            display: none;
          }
          &::after {
            color: #fc0;
            content: url('data:image/svg+xml; utf8, <svg xmlns="http://www.w3.org/2000/svg" height="10" width="10" viewBox="0 0 407.436 407.436"><path style="fill: ${colors.color ===
            '#fff'
              ? 'white'
              : 'black'}" d="M112.814 0 91.566 21.178l181.946 182.54-181.946 182.54 21.248 21.178 203.055-203.718z"/></svg>');
            margin-left: ${theme.spacing('xs2')};
          }
        }
      }
    `
  };

  const { isTravelGuideContinent, isTravelGuideCountry } =
    getTravelGuideInfo(content);

  const urlParts = content.url.path.split('/');

  const items = [];
  if (isTravelGuideContinent || isTravelGuideCountry) {
    items.push({
      text: t('Travel Guides'),
      link: urlParts.slice(0, 3).join('/')
    });
  }

  if (isTravelGuideContinent) {
    items.push({ text: content.primaryTaxonomy.entity.name });
  } else if (isTravelGuideCountry) {
    items.push({
      text:
        content.primaryTaxonomy.entity.parents?.[0]?.name ||
        urlParts.slice(3, 4),
      link: urlParts.slice(0, 4).join('/')
    });
    items.push({ text: content.primaryTaxonomy.entity.name });
  }

  return items.length > 0 ? (
    <ul css={styles.main}>
      {items.map(item => {
        const liInner = item.link ? (
          <SmartLink to={item.link}>{item.text}</SmartLink>
        ) : (
          item.text
        );
        return <li key={item.text}>{liInner}</li>;
      })}
    </ul>
  ) : (
    <div />
  );
};
TravelGuideBreadcrumbsParagraphInner.propTypes = {
  content: PropTypes.objectOf(PropTypes.any).isRequired,
  colors: PropTypes.objectOf(PropTypes.any).isRequired
};

export const TravelGuideBreadcrumbsParagraph = ParagraphWrapper(
  TravelGuideBreadcrumbsParagraphInner
);
